<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Alejandro Orozco Marulanda                                    ###### -->
<!-- ###### @date: Diciembre 2024                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
     <!--Snackbar de elemento exitoso-->
     <v-snackbar v-model="snackbarValue" :color="snackbarColor ? 'success' : 'error'" timeout="2000">
      {{ snackbarMessage }}
    </v-snackbar>

    <!-- FILTROS -->
    <section class= "d-grid">
      <section class= "d-flex">
        <!--Colaborador Evaluado-->
        <FilterCustomized
          v-model="buscar.idColaborador"
          :items="listaBusqueda.colaborador" item-value="id" item-text="name" label="Colaborador" :style="{ maxWidth: '50%' }"
          @update:search-input= "(value) =>llenarLista('colaborador',listaBusqueda.colaborador,value,'idColaborador','nombre',false)"
          @input="filtrarItems()"
        />
        <!--Periodo de Evaluación-->
        <FilterCustomized
          v-model="buscar.idColaboradorReemplazo" 
          :items="listaBusqueda.colaboradorReemplazo" item-value="id" item-text="name" label="Colaborador que reemplaza" :style="{ maxWidth: '50%' }"
          @update:search-input= "(value) =>llenarLista('colaborador',listaBusqueda.colaboradorReemplazo,value,'idColaborador','nombre')"
          @input="filtrarItems()"
        />
        </section>
    </section>

    <!-- Tabla de items -->
    <v-data-table class="elevation mt-4" :items="tabla.items" :loading="tabla.loading" fixed-header
      :headers="tabla.headers" :footer-props="tabla.footerProps" :items-per-page="tabla.itemsPerPage"
      :page="tabla.page" @update:items-per-page="(i) => tabla.itemsPerPage = i" @update:page="(p) => tabla.page = p"
      :server-items-length="tabla.totalPage" height="64vh" @mouseenter="isMenuOpen = false">

      <template v-slot:[`item.nombre`]="{ item }">
        <span>{{ `${item.colaborador.nombre} ${item.colaborador.apellidos}` }}</span>
      </template>

      <template v-slot:[`item.nombreReemplazo`]="{ item }">
        <span>{{ `${item.colaboradorReemplazo.nombre} ${item.colaboradorReemplazo.apellidos}` }}</span>
      </template>

      <template v-slot:[`item.periodo`]="{ item }">
        <span>{{ definePeriodo(item.periodoCambioValue) }}</span>
      </template>

      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterCustomized from "@/components/human-talent/FilterCustomized.vue"
import { required } from "@/validators/validations";

export default {
  name:"Reemplazos",
  components:{
    FilterCustomized,
  },
  data(){
    return {
      ruta:"msa-hr/api/reemplazo",
      lastmodifiedby:"",
      userRoles : {},
      rules: {
        required: [required]
      },
      snackbarValue: false,
      snackbarMessage: '',
      snackbarColor:false,
      buscar:{
        idColaborador:null,
        idColaboradorReemplazo:null,
      },
      listaBusqueda:{
        colaborador:[],
        colaboradorReemplazo:[],
      },
      tabla: {
        loading: false,
        items: [],
        headers: [
          { text: "ID", width: "20%", sortable: false, align: "center", value: "colaborador.numeroDocumento" },
          { text: "NOMBRE", width: "30%", sortable: false, align: "center", value: "nombre" },
          { text: "NOMBRE REEMPLAZO", width: "30%", sortable: false, align: "center", value: "nombreReemplazo" },
          { text: "PERIODO DE CAMBIO", width: "20%", sortable: false, align: "center", value: "periodo" },
        ],
        footerProps: {
          'items-per-page-options': [10, 20, 30, 40],
          'items-per-page-text': 'Items por página:',
          'show-current-page': true,
          'show-first-last-page': true
        },
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
      },
    }
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
  },
  watch: {
    // Escucha los cambios en la página actual de la tabla de items.
    // Si cambia, llama a la función para listar las items.
    "tabla.page": function () {
      this.listarItems();
    },
    // Escucha cambios en la cantidad de ítems por página en la tabla de items.
    // Si cambia, reinicia la página actual a 1 y lista los items.
    "tabla.itemsPerPage": function () {
      this.tabla.page = 1;
      this.listarItems();
    },
  },
  methods:{
    //Manejo de solicitudes al BackEnd
    async listar(nombre, Url, soloActivos,codStone,tipoCompetenciaValue,size=100) {
      let lista = await this.$http.get(`msa-hr/api/${Url}/listar`, {
        params: {
          idEmpresa: this.enterprise.code,
          nombre,
          codStone,
          tipoCompetenciaValue,
          nombreCompleto: nombre,
          eliminado: soloActivos ? !soloActivos : '',
          size: size
        },
      });
      return lista.data.content ? lista.data.content : [];
    },

    //Método genérico para el Llenado de una lista para filtro
    async llenarLista(Url,listado,nombreBusqueda, nombreId, nombreItem = "nombre", soloActivos) {
      try{
        //Trae la lista desde el back
        const lista = await this.listar(nombreBusqueda, Url,soloActivos);
        // Vaciar el listado original
        listado.length = 0;
        // Llenar el listado con los nuevos elementos
        lista.forEach(item => {
          listado.push({
            name: `${item[nombreItem].trim()}${item.apellidos? ` ${item.apellidos.trim()}` : ''}` ,
            id: item[nombreId]
          });
        });
      }catch(error){
        console.error(error)
      }
    },

    //Define periodo de cambio
    definePeriodo(periodo){
      switch(periodo){
        case 'A':
          return 'Inmediato';
        case 'B':
          return 'De 1 a 2 años';
        case 'C':
          return 'De 2 a 4 años';
        case 'D':
          return 'Más de 4 años'
      }
    },
    async filtrarItems(){
      this.tabla.page = 1;
      this.listarItems();
    },
    async listarItems() {       
      this.tabla.loading = true;
      try{
          const response = await this.$http
          .get(`${this.ruta}/listar`, {
            params: {
              idEmpresa: this.enterprise.code,
              idColaborador: this.buscar.idColaborador,
              idColaboradorReemplazo:this.buscar.idColaboradorReemplazo,
              page: this.tabla.page - 1,
              size: this.tabla.itemsPerPage,
            },
          })
      
        this.tabla.items = response.data.content;
        this.tabla.totalPage = response.data.totalElements;
        this.tabla.loading = false;

      }catch(error) {
        console.log(error);
        this.tabla.loading = false;
      }
    },
  },
  async created() {
    this.userRoles = this.auth.roles;
    try{
      await this.listarItems();
    }catch(error){
      console.log(error)
    }
  },
  mounted() {
    this.llenarLista('colaborador',this.listaBusqueda.colaborador,'','idColaborador','nombre',false);
    this.llenarLista('colaborador',this.listaBusqueda.colaboradorReemplazo,'','idColaborador','nombre',false);
    this.lastmodifiedby = this.auth.username.toUpperCase();
  },
}
</script>
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 85vh;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
</style>